<template>
  <div v-if="updateTime">
    <span class="text-muted font-12"
      >Güncelleme: {{ updateTime | dateFormat }}</span
    >
  </div>
</template>
<script>
export default {
  name: "LastUpdate",
  props: {
    updateTime: {
      default: null,
    },
  },
};
</script>